import styled, { css } from 'styled-components';
import * as LSTVGlobals from '~/globals';
import {
  CARD_CONTAINER_TYPE_GRID,
  CARD_CONTAINER_TYPE_HSCROLL,
  CARD_CONTAINER_TYPE_MASONRY,
  CARD_TYPE_WEDDING_VENDOR,
  CARD_ORIENTATION_LANDSCAPE,
  CARD_ORIENTATION_PORTRAIT,
  CARD_TYPE_MINI_ROUND_IMAGE,
  CARD_TYPE_TEAM_MEMBER,
  CARD_TYPE_SHOPPING_ITEM,
  CARD_TYPE_CALENDAR_ITEM,
  CARD_TYPE_VENDOR_GENERIC,
} from './index';
import { CardContainerMode, CardProps, CardType } from './types';

export const CardOutline = styled.div<{ $cardProps: CardProps; $imageOnly?: boolean }>`
  position: relative;
  border-radius: 10px;
  background: ${LSTVGlobals.CARD_BACKGROUND};
  width: 100%;
  text-decoration: none;
  box-shadow: 0px 0px 6px rgba(183, 183, 183, 0.25);
  transition: 0.3s ease-in;

  &:hover {
    box-shadow: 0px 0px 6px 4px rgba(183, 183, 183, 0.25);
  }

  ${(props) =>
    props.$cardProps.containerMode &&
    props.$cardProps.containerMode === CARD_CONTAINER_TYPE_GRID &&
    css`
      height: 100%;
    `}
  ${(props) =>
    props.$cardProps.containerMode &&
    props.$cardProps.containerMode === CARD_CONTAINER_TYPE_MASONRY &&
    css`
      margin-bottom: 15px;
    `}
  ${(props) =>
    props.$cardProps.containerMode &&
    props.$cardProps.containerMode === CARD_CONTAINER_TYPE_HSCROLL &&
    css`
      width: ${LSTVGlobals.HSCROLL_CARD_SIZES_LAPTOP}px;
      height: 100%;

      @media ${LSTVGlobals.UserDevice.isMobileS} {
        width: ${LSTVGlobals.HSCROLL_CARD_SIZES_MOBILE_SMALL}px;
      }

      @media ${LSTVGlobals.UserDevice.isMobileM} {
        width: ${LSTVGlobals.HSCROLL_CARD_SIZES_MOBILE_MEDIUM}px;
      }

      @media ${LSTVGlobals.UserDevice.isMobileL} {
        width: ${LSTVGlobals.HSCROLL_CARD_SIZES_MOBILE_LARGE}px;
      }

      @media ${LSTVGlobals.UserDevice.isTablet} {
        width: ${LSTVGlobals.HSCROLL_CARD_SIZES_TABLET}px;
      }
    `}

  ${(props) =>
    props.$imageOnly &&
    css`
      left: 20px;
    `};

  ${(props) =>
    props.$cardProps.orientation === CARD_ORIENTATION_LANDSCAPE &&
    css`
      border-radius: 3px;
    `};

  ${(props) =>
    props.$cardProps.cardType === CARD_TYPE_WEDDING_VENDOR &&
    css`
      border-radius: 10px;
      padding: 10px 57px 10x 10px;
      height: 125px;
      background: ${props.$cardProps.bg_color};
    `};

  ${(props) =>
    props.$cardProps.cardType === CARD_TYPE_CALENDAR_ITEM &&
    css`
      border-radius: 10px;
      background: ${(props) => props.theme.highlight_7_0};
    `};

  ${(props) =>
    (props.$cardProps.cardType === CARD_TYPE_MINI_ROUND_IMAGE || props.$cardProps.cardType === CARD_TYPE_TEAM_MEMBER) &&
    css`
      border-radius: 0;
      border: none;

      box-shadow: none;

      &:hover {
        box-shadow: none;
      }
    `};
`;

export const VideoDuration = styled.div<{ $cardProps: CardProps }>`
  position: absolute;
  border-radius: 4px;
  bottom: 8px;
  right: 8px;
  height: 31px;
  width: 51px;
  text-align: center;
  font-weight: normal;
  line-height: 31px;
  background: ${LSTVGlobals.BLACK};
  color: ${LSTVGlobals.ABSOLUTE_WHITE};

  ${(props) =>
    props.$cardProps.orientation === CARD_ORIENTATION_LANDSCAPE &&
    css`
      font-size: 0.75rem;
      height: 1rem;
      line-height: 1rem;
      width: 42px;
    `};
`;

export const PremiumBadge = styled.div`
  position: absolute;
  border-radius: 3px;
  top: 16px;
  right: 16px;
  height: 23px;
  padding: 1px 8px;
  text-align: center;
  font-weight: ${LSTVGlobals.FONT_WEIGHT_MEDIUM};
  line-height: 23px;
  background: ${(props) => props.theme.highlight_7_0};
  color: ${(props) => props.theme.white};
`;

export const ColorBar = styled.div<{ $cardProps: CardProps }>`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 7px;
  width: 61px;
  background: ${(props) => props.color};
  z-index: ${LSTVGlobals.Z_INDEX_6_OF_100};

  ${(props) =>
    props.$cardProps.imageOnly &&
    css`
      left: 20px;
    `};

  ${(props) =>
    props.$cardProps.orientation === CARD_ORIENTATION_LANDSCAPE &&
    css`
      border-radius: 3px;
    `};
`;

export const GradientBackground = styled(ColorBar)`
  width: 100%;
  height: 100%;
  left: 0;
  z-index: ${LSTVGlobals.Z_INDEX_5_OF_100};
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.67) 0%, rgba(0, 0, 0, 0.03) 50%, rgba(0, 0, 0, 0) 100%);
`;

export const CardElements = styled.div<{ $cardProps: CardProps }>`
  display: flex;
  flex-direction: ${(props) => (props.$cardProps.orientation === CARD_ORIENTATION_PORTRAIT ? 'column' : 'row')};

  ${(props) =>
    props.$cardProps.orientation === CARD_ORIENTATION_LANDSCAPE &&
    css`
      align-items: center;
    `}

  ${({ $cardProps: { containerMode } }) =>
    (containerMode === CARD_CONTAINER_TYPE_GRID || containerMode === CARD_CONTAINER_TYPE_HSCROLL) &&
    css`
      height: 100%;
    `}
`;

export const CardThumbnail = styled.div<{ $cardProps: CardProps }>`
  flex: 0 0 auto;
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
  width: 100%;

  &:before {
    content: '';
    display: block;
    padding-bottom: 56.25%;
  }

  & > img {
    position: absolute;
    top: 0;
  }

  ${(props) =>
    props.$cardProps.imageOnly &&
    css`
      border-radius: 14px 14px 14px 14px;
    `}

  ${(props) =>
    props.$cardProps.orientation === CARD_ORIENTATION_LANDSCAPE &&
    css`
      height: 88px;
      width: 155.76px;
      min-width: unset;
      min-height: unset;
      max-height: unset;
      max-width: unset;
      flex: 0 0 156px;
      border-radius: 3px;
    `}


  ${(props) =>
    props.$cardProps.cardType === CARD_TYPE_MINI_ROUND_IMAGE &&
    css`
      height: 34px;
      width: 34px;
      min-width: unset;
      min-height: unset;
      max-height: unset;
      max-width: unset;
      flex: 0 0 34px;
      border-radius: 50%;
    `}

  ${(props) =>
    props.$cardProps.cardType === CARD_TYPE_TEAM_MEMBER &&
    css`
      clip-path: circle(60px at center);
    `}

  ${(props) =>
    props.$cardProps.cardType === CARD_TYPE_SHOPPING_ITEM &&
    css`
      max-height: unset;
      min-height: unset;
      overflow: unset;
      width: 100%;
      height: 300px;

      img {
        border-top-left-radius: 14px;
        border-top-right-radius: 14px;
        object-position: top;
      }
    `};
`;

export const CardDetails = styled.div<{ $cardProps: CardProps }>`
  padding: 10px 26px 10px 26px;
  color: ${(props) => props.theme.black};
  overflow: hidden;
  /* min-height: 60px; */
  flex: 1 0;

  ${(props) =>
    props.$cardProps.imageOnly &&
    css`
      display: block;
      position: absolute;
      bottom: 10px;
      padding: 0 0 10px 0;
      left: 20px;
      color: white;
      z-index: 8;
      width: calc(100% - 30px);
    `};

  ${(props) =>
    props.$cardProps.orientation === CARD_ORIENTATION_LANDSCAPE &&
    css`
      //height: 100%;
      padding: 0 0 0 20px;
    `}

  ${(props) =>
    props.$cardProps.cardType === CARD_TYPE_WEDDING_VENDOR &&
    css`
      padding: 10px;
    `};

  ${(props) =>
    props.$cardProps.cardType === CARD_TYPE_SHOPPING_ITEM &&
    css`
      padding: 15px 13px 15px 13px;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      & button: {
        width: 100%;
      }
    `};

  ${(props) =>
    props.$cardProps.cardType === CARD_TYPE_CALENDAR_ITEM &&
    css`
      @media ${LSTVGlobals.UserDevice.tablet} {
        padding: 0;
      }
    `};

  ${(props) =>
    props.$cardProps.cardType === CARD_TYPE_MINI_ROUND_IMAGE &&
    css`
      padding: 10px 8px 10px 8px;
      display: flex;
      flex-direction: column;
    `};

  ${(props) =>
    props.$cardProps.cardType === 'business-generic' &&
    css`
      padding: 15px 26px 15px 26px;
    `};
`;

export const VideoCardTagsContainer = styled.div`
  padding: 8px 26px 26px 26px;
`;

export const CardParagraph = styled.div`
  font-family: 'Calibre', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 1.125rem;
  line-height: 1.312rem;
  position: relative;
`;

export const BlogCardPreview = styled(CardParagraph)`
  font-family: 'Calibre', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 0.937rem;
  line-height: 1.125rem;
  position: relative;
  max-height: 3.375rem;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  display: -webkit-box;
`;

export const CardTitle = styled.div`
  font-family: 'Calibre', sans-serif;
  font-weight: normal;
  font-style: normal;
  max-height: 3.12rem;
  position: relative;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  display: -webkit-box;
`;

export const CardInformation = styled.div<{ $cardProps: CardProps }>`
  font-family: Calibre;
  font-style: normal;
  font-size: 0.973rem;
  font-weight: normal;
  line-height: 1.125rem;

  ${(props) =>
    (props.$cardProps.cardType === CARD_TYPE_SHOPPING_ITEM || props.$cardProps.cardType === CARD_TYPE_VENDOR_GENERIC) &&
    css`
      color: ${(props) => props.theme.darkGrey};
    `};
`;

export const Price = styled(CardInformation)`
  color: ${(props) => props.theme.black};
  font-weight: 500;
`;

export const PriceWas = styled.span`
  color: ${(props) => props.theme.darkGrey};
  font-weight: 500;
  font-size: 0.973rem;
  font-weight: normal;
  text-decoration: line-through;
`;

export const LineLimiter = styled.div<{ $lines: string | number }>`
  -webkit-line-clamp: ${(props) => props.$lines || '2'};
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  display: -webkit-box;
  overflow: hidden;
`;

export const CardStats = styled.div`
  font-family: Calibre;
  font-style: normal;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.1rem;
`;

export const CardTags = styled.div`
  font-size: 0.973rem;
  font-weight: ${LSTVGlobals.FONT_WEIGHT_MEDIUM};
  line-height: 1.125rem;
  color: ${(props) => props.theme.black};
`;

export const BusinessRoles = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  flex: 1;
  width: 100%;
  height: 40px;
  border-radius: 0 0 14px 14px;
  flex: 0 0 36px;
`;

export const BusinessRole = styled.div<{ $bgColor: string }>`
  flex: 1;
  background: ${(props) => props.$bgColor};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  color: ${(props) => props.theme.white};
  font-size: 0.973rem;
  line-height: 0.973rem;
  font-weight: bold;
`;

export const DiscountLabel = styled.span`
  color: ${(props) => props.theme.darkGrey};
`;

export const WeddingBusinessPremiumTag = styled.span<{ $cardProps: CardProps }>`
  display: inline-block;
  width: fit-content;
  padding: 1px 8px 1px 8px;
  border-radius: 3px;
  background: ${(props) => props.theme.white};
  font-weight: 500;
  font-size: 0.9rem;
  letter-spacing: 0.02rem;
  color: ${(props) => props.$cardProps.bg_color};
  height: 19px;
  line-height: 19px;
`;

export const WeddingBusinessName = styled.h5`
  font-size: 1.125rem;
  line-height: 1.4rem;
  max-height: 2.8rem;
  color: ${(props) => props.theme.white};
  font-weight: 500;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  display: -webkit-box;
  overflow: hidden;
  height: 100%;

  @media ${LSTVGlobals.UserDevice.isMobile} {
    font-size: 1.125rem;
    line-height: 1.312rem;
    max-height: 2.624rem;
  }
`;

export const BusinessRoleName = styled(WeddingBusinessName)`
  font-size: 0.75rem;
  line-height: 0.8rem;
  max-height: 1.6rem;

  @media ${LSTVGlobals.UserDevice.isMobile} {
    font-size: 0.937rem;
    line-height: 1rem;
    max-height: 2rem;
  }
`;

export const ClickableCard = styled.div<{ $containerMode: CardContainerMode; $cardType: CardType }>`
  cursor: pointer;
  ${(props) =>
    props.$containerMode === CARD_CONTAINER_TYPE_GRID &&
    css`
      height: 100%;
    `}

  ${(props) =>
    props.$cardType === CARD_TYPE_TEAM_MEMBER &&
    css`
      cursor: default;
    `};
`;

export const FolderPlusSign = styled.h5`
  margin-top: auto;
  min-width: 1.5rem;
  text-align: right;
`;

export const FolderTitle = styled.h5`
  flex: 1 0;
`;

export const CalendarCardDate = styled.p`
  font-size: 2.562rem;
  line-height: 2.25rem;
  font-weight: 600;
  color: ${(props) => props.theme.white};
`;

export const CalendarCardPlace = styled(CalendarCardDate)`
  font-size: 1.312rem;
  line-height: 1.562rem;
`;

export const CalendarCardLocation = styled(CalendarCardDate)`
  font-size: 1rem;
  line-height: 1.187rem;
  font-weight: 500;
`;

export const PromoVideoTitle = styled.h5``;

export const CardTagContainer = styled.div`
  display: inline-block;
  background: ${(props) => props.theme.midGrey};
  padding: 3.5px 6px 3.5px 6px;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 3px;
  font-weight: 500;
  font-size: 0.937rem;
  line-height: 1.115rem;
`;

export const MiniRoundedCardTitle = styled.div`
  font-size: 1rem;
  line-height: 1.115rem;
  font-weight: 500;
`;

export const MiniRoundedCardSubTitle = styled.div`
  display: inline;
  font-size: 0.687rem;
  line-height: 0.818rem;
  font-weight: 500;
  color: ${(props) => props.theme.darkGrey};
`;

export const MiniRoundedCardSubTitleBold = styled(MiniRoundedCardSubTitle)`
  display: inline;
  font-weight: 700;
`;

export const BusinessStatElement = styled.div`
  display: inline-block;
`;

export const AdLabelContainer = styled.div`
  position: absolute;
  top: -21px;
  right: 0;
  font-size: 13px;
  font-weight: 500;
  color: ${(props) => props.theme.darkerGrey};
`;

export const AdFooterContainer = styled.div`
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  border-radius: 0 0 14px 14px;
  flex: 0 0 36px;
  background: ${(props) => props.theme.black};
  color: ${(props) => props.theme.white};
  font-size: 0.973rem;
  line-height: 0.973rem;
  font-weight: bold;
  padding: 0 20px 0 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
