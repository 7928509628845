import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import Typography from '~/atoms/Typography';
import BaseCtaButton from '~/components/buttons/BaseCtaButton';
import { ArrowRightIcon, LSTVSVG } from '~/components/InlineSVG';
import LSTVImage from '~/components/LSTVImage';
import LSTVLink from '~/components/LSTVLink';
import * as LSTVGlobals from '~/globals';
import { Media } from '~/styles/media';
import {
  Flex,
  GenericContainer,
  getRolesStringFromBusiness,
  HorizontalSpacer,
  secsToTimeStr,
  shortHandValue,
  VerticalSpacer,
} from '~/utils/LSTVUtils';
import * as S from './index.styles';
import { AdFooterContainer, AdLabelContainer, CardInformation } from './index.styles';
import Image from 'next/image';

//    ____              _   _____
//   / ___|__ _ _ __ __| | |_   _|   _ _ __   ___  ___
//  | |   / _` | '__/ _` |   | || | | | '_ \ / _ \/ __|
//  | |__| (_| | | | (_| |   | || |_| | |_) |  __/\__ \
//   \____\__,_|_|  \__,_|   |_| \__, | .__/ \___||___/
//                               |___/|_|

export const CARD_TYPE_WEDDING_VENDOR = 'wedding-business';
export const CARD_TYPE_VIDEO = 'video';
export const CARD_TYPE_BUSINESS = 'business';
export const CARD_TYPE_BUSINESS_DETAILS = 'business-details';
export const CARD_TYPE_ARTICLE = 'article';
export const CARD_TYPE_VIBE = 'vibe';
export const CARD_TYPE_SHOPPING_ITEM = 'shopping-iten';
export const CARD_TYPE_FOLDER = 'folder';
export const CARD_TYPE_CALENDAR_ITEM = 'calendar-item';
export const CARD_TYPE_PROMO_VIDEO = 'promo-video';
export const CARD_TYPE_MINI_ROUND_IMAGE = 'mini-round-image';
export const CARD_TYPE_VENDOR_GENERIC = 'business-generic';
export const CARD_TYPE_TEAM_MEMBER = 'team-member';
export const CARD_TYPE_AD = 'ad';

// card container types

export const CARD_CONTAINER_TYPE_GRID = 'grid';
export const CARD_CONTAINER_TYPE_MASONRY = 'masaonry';
export const CARD_CONTAINER_TYPE_HSCROLL = 'h-scroll';

// card orientation

export const CARD_ORIENTATION_PORTRAIT = 'portrait';
export const CARD_ORIENTATION_LANDSCAPE = 'landscape';

// card constraints

export const CARD_CONSTRAINTS_NO_LOCATION = 'no_location';
export const CARD_CONSTRAINTS_NO_VIDEOGRAPHER = 'no_videographer';

/**
 * This is used to change thumbnail to an animating gif for a video on mobile,
 * where hover effect isn't available. Since we don't want a lot of videos
 * animating at the same time on tablets, we animate whichever video becomes
 * fully visible (as a result of page being loaded or user scrolling), but only
 * if there is a single such video.
 */
const intersectionObserver =
  typeof window !== 'undefined' && window.matchMedia('(hover: none)').matches
    ? new IntersectionObserver(
        (entries) => {
          const oldFullyVisible = new Set(
            [...observedElements.entries()]
              .filter(([, { intersectionRatio }]) => intersectionRatio === 1)
              .map(([el]) => el)
          );
          // Update ratios stored in observedElements.
          entries.forEach(({ target, intersectionRatio }) => {
            const observedElement = observedElements.get(target);
            if (observedElement) {
              observedElements.set(target, { intersectionRatio, callback: observedElement.callback });
            }
          });
          // If there is a single new fully visible element, animate it.
          const currentFullyVisible = [...observedElements.entries()]
            .filter(([, { intersectionRatio }]) => intersectionRatio === 1)
            .map(([el]) => el);
          const newFullyVisible = currentFullyVisible.filter((el) => !oldFullyVisible.has(el));
          if (newFullyVisible.length === 1) {
            [...observedElements.entries()].forEach(([el, { callback }]) => callback(el === newFullyVisible[0]));
          }
        },
        {
          threshold: [0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95, 1],
          // Mobile header.
          rootMargin: '-75px 0px 0px 0px',
        }
      )
    : undefined;

/**
 * A map where for each thumbnail element that's currently present in the DOM,
 * we store its current intersectionRatio (when known) and a callback to be called to
 * start or stop the animation.
 */
const observedElements = new Map<Element, { intersectionRatio?: number; callback: (active: boolean) => void }>();

const getBottomSection = (cardTheme) => {
  switch (cardTheme.cardType) {
    case CARD_TYPE_BUSINESS:
    case CARD_TYPE_VENDOR_GENERIC: {
      const { roles } = cardTheme;
      const moreThanOneFamily = roles.length > 1;
      let businessRoles;

      if (moreThanOneFamily) {
        businessRoles = roles.map(
          (role, index) =>
            index < 2 && (
              <S.BusinessRole key={index} $bgColor={role.bg_color}>
                {role.name}
              </S.BusinessRole>
            )
        );
      } else {
        let roleNames = getRolesStringFromBusiness(roles);
        businessRoles = <S.BusinessRole $bgColor={roles[0]?.bg_color}>{roleNames}</S.BusinessRole>;
      }

      return <S.BusinessRoles>{businessRoles}</S.BusinessRoles>;
    }
    case CARD_TYPE_AD: {
      return (
        <AdFooterContainer>
          {cardTheme.footerCaption}
          <ArrowRightIcon />
        </AdFooterContainer>
      );
    }
    case CARD_TYPE_VIDEO: {
      {
        if (cardTheme.orientation === CARD_ORIENTATION_PORTRAIT && cardTheme.vibes.length > 0) {
          return (
            <S.VideoCardTagsContainer>
              <S.CardTags>
                {cardTheme.vibes.map((data, index) => {
                  return <S.CardTagContainer key={index}>{data}</S.CardTagContainer>;
                })}
              </S.CardTags>
            </S.VideoCardTagsContainer>
          );
        }
      }
    }
  }
};

const getCardDetails = (cardTheme) => {
  switch (cardTheme.cardType) {
    case CARD_TYPE_VIDEO:
      return (
        <>
          <Typography variant="h5" fontWeight="600">
            {cardTheme.coupleNames}
          </Typography>

          {(!cardTheme.constraints || cardTheme.constraints !== CARD_CONSTRAINTS_NO_VIDEOGRAPHER) && (
            <S.CardParagraph>{cardTheme.videographer}</S.CardParagraph>
          )}
          {cardTheme.orientation === CARD_ORIENTATION_PORTRAIT && <VerticalSpacer space={'10'} />}
          <S.CardInformation $cardProps={cardTheme}>
            {(!cardTheme.constraints || cardTheme.constraints !== CARD_CONSTRAINTS_NO_VIDEOGRAPHER) && (
              <>
                {cardTheme.venueName}
                <br />
              </>
            )}{' '}
            {(!cardTheme.constraints || cardTheme.constraints) !== CARD_CONSTRAINTS_NO_LOCATION && cardTheme.location}
          </S.CardInformation>

          <S.CardStats>
            {(cardTheme.views > LSTVGlobals.ACTION_BAR_VIEWS_DISPLAY_THRESHOLD ||
              cardTheme.likes > LSTVGlobals.ACTION_BAR_LIKES_DISPLAY_THRESHOLD) && <VerticalSpacer space={'10'} />}
            {cardTheme.views > LSTVGlobals.ACTION_BAR_VIEWS_DISPLAY_THRESHOLD && (
              <>
                {shortHandValue(cardTheme.views)} <span style={{ fontWeight: 'bold' }}>Views</span>
                <HorizontalSpacer space={10} />
              </>
            )}
            {cardTheme.likes > LSTVGlobals.ACTION_BAR_LIKES_DISPLAY_THRESHOLD && (
              <>
                {shortHandValue(cardTheme.likes)}{' '}
                <span style={{ fontWeight: 'bold', fontSize: '0.973rem' }}>Likes</span>
              </>
            )}
            {(cardTheme.views > LSTVGlobals.ACTION_BAR_VIEWS_DISPLAY_THRESHOLD ||
              cardTheme.likes > LSTVGlobals.ACTION_BAR_LIKES_DISPLAY_THRESHOLD) && <VerticalSpacer space={'10'} />}
          </S.CardStats>
        </>
      );
    case CARD_TYPE_BUSINESS:
      return (
        <>
          <Typography variant="h5" fontWeight="600">
            {cardTheme.name}
          </Typography>
          <S.CardInformation $cardProps={cardTheme}>{cardTheme.location?.display_name || ''}</S.CardInformation>
          <VerticalSpacer space={'10'} />
          <S.CardStats>
            {cardTheme.likes > LSTVGlobals.ACTION_BAR_LIKES_DISPLAY_THRESHOLD && (
              <S.BusinessStatElement>
                {shortHandValue(cardTheme.likes)}{' '}
                <span style={{ fontWeight: 'bold', fontSize: '0.973rem' }}>Likes</span>
                <HorizontalSpacer space={10} />
              </S.BusinessStatElement>
            )}

            {cardTheme.videos > LSTVGlobals.ACTION_BAR_VIDEO_COUNT_DISPLAY_THRESHOLD && (
              <S.BusinessStatElement>
                {shortHandValue(cardTheme.videos)}{' '}
                <span style={{ fontWeight: 'bold', fontSize: '0.973rem' }}>Videos</span>
              </S.BusinessStatElement>
            )}

            {cardTheme.subscribers > LSTVGlobals.ACTION_BAR_SUBSCRIBER_DISPLAY_THRESHOLD && (
              <S.BusinessStatElement>
                {shortHandValue(cardTheme.subscribers)} <span style={{ fontWeight: 'bold' }}>Subscribers</span>
                <HorizontalSpacer space={10} />
              </S.BusinessStatElement>
            )}
          </S.CardStats>
        </>
      );
    case CARD_TYPE_BUSINESS_DETAILS:
      return (
        <>
          <S.CardTitle>{cardTheme.title}</S.CardTitle>
          <VerticalSpacer space={'10'} />
        </>
      );
    case CARD_TYPE_ARTICLE: {
      let tags = cardTheme.tags.map((data, index) => {
        return <S.CardTagContainer key={index}>{data}</S.CardTagContainer>;
      });

      return (
        <>
          <S.CardTitle>
            <Typography variant="h5" fontWeight="600">
              {cardTheme.title}
            </Typography>
          </S.CardTitle>
          <VerticalSpacer space={'10'} />
          <S.BlogCardPreview>{cardTheme.contentPreview}</S.BlogCardPreview>
          <VerticalSpacer space={'10'} />
          <S.CardStats>
            {cardTheme.views > LSTVGlobals.ACTION_BAR_VIEWS_DISPLAY_THRESHOLD && (
              <>
                {shortHandValue(cardTheme.views)} <span style={{ fontWeight: 'bold' }}>Views</span>
                <HorizontalSpacer space={10} />
              </>
            )}
            {cardTheme.likes > LSTVGlobals.ACTION_BAR_LIKES_DISPLAY_THRESHOLD && (
              <>
                {shortHandValue(cardTheme.likes)}{' '}
                <span style={{ fontWeight: 'bold', fontSize: '0.973rem' }}>Likes</span>
                <HorizontalSpacer space={10} />
              </>
            )}

            {(cardTheme.views > LSTVGlobals.ACTION_BAR_VIEWS_DISPLAY_THRESHOLD ||
              cardTheme.likes > LSTVGlobals.ACTION_BAR_LIKES_DISPLAY_THRESHOLD) && <VerticalSpacer space={10} />}
          </S.CardStats>
          <S.CardTags>{tags}</S.CardTags>
        </>
      );
    }
    case CARD_TYPE_VIBE:
      return (
        <>
          <Typography variant="h5" fontWeight="600">
            {cardTheme.name}
          </Typography>
          <S.CardStats>
            {cardTheme.videos > LSTVGlobals.ACTION_BAR_VIDEO_COUNT_DISPLAY_THRESHOLD && (
              <>
                {shortHandValue(cardTheme.videos)}{' '}
                <span style={{ fontWeight: 'bold', fontSize: '0.973rem' }}>Videos</span>
              </>
            )}
          </S.CardStats>
        </>
      );
    case CARD_TYPE_WEDDING_VENDOR:
      return (
        <>
          <Flex flexDirection={'row'} flexWrap={'no-wrap'} alignItems={'stretch'} height={'100%'} flex={'1'}>
            <Flex flexDirection={'column'} alignItems={'stretch'} width={'100%'}>
              {cardTheme.premium && (
                <S.WeddingBusinessPremiumTag $cardProps={cardTheme}>Suggested</S.WeddingBusinessPremiumTag>
              )}
              <VerticalSpacer space={5} />
              <GenericContainer height={'100%'}>
                <S.WeddingBusinessName>{cardTheme.name}</S.WeddingBusinessName>
              </GenericContainer>
              <VerticalSpacer space={10} />
              <Flex flexDirection={'row'} flexWrap={'no-wrap'} alignItems={'center'} width={'100%'} height={'60px'}>
                <Flex flexDirection={'column'}>
                  <S.BusinessRoleName>{cardTheme.role_name}</S.BusinessRoleName>
                </Flex>
                <Flex flexDirection={'column'} minWidth={'35px'}>
                  <Flex
                    flex={'0 0 34px'}
                    flexDirection={'column'}
                    justifyItems={'flex-end'}
                    justifyContent={'flex-end'}
                  >
                    <LSTVSVG imageWidth={'36px'} imageHeight={'36px'} icon={'wedding-business-arrow'} />
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </>
      );
    case CARD_TYPE_SHOPPING_ITEM:
      return (
        <>
          <GenericContainer padding={'0 15px 0 15px'}>
            <S.LineLimiter $lines={2}>
              <Typography variant="h5" fontWeight="600">
                {cardTheme.name}
              </Typography>
            </S.LineLimiter>
            <VerticalSpacer space={8} />

            <S.CardInformation $cardProps={cardTheme}>{cardTheme.soldBy}</S.CardInformation>
            <VerticalSpacer space={4} />
            <S.CardInformation $cardProps={cardTheme}>{cardTheme.description}</S.CardInformation>
            <VerticalSpacer space={8} />
            <S.Price $cardProps={cardTheme}>{cardTheme.price}</S.Price>
            {cardTheme.priceWas && (
              <>
                <S.PriceWas>{cardTheme.priceWas}</S.PriceWas>{' '}
                <S.DiscountLabel>{cardTheme.discountLabel || 'List Price'}</S.DiscountLabel>
              </>
            )}
            <VerticalSpacer space={12} />
          </GenericContainer>
          <Media at="xs">
            <BaseCtaButton title={cardTheme.ctaLabel || 'Shop Now'} size="fullWidth" />
          </Media>
          <Media greaterThan="xs">
            <BaseCtaButton title={cardTheme.ctaLabel || 'Shop Now'} size="fullWidth" />
          </Media>
        </>
      );
    case CARD_TYPE_TEAM_MEMBER:
      return (
        <>
          <GenericContainer textAlign={'center'} padding={'10px 15px 0 15px'}>
            <S.LineLimiter $lines={2}>
              <Typography variant="h5" fontWeight="600">
                {cardTheme.name}
              </Typography>
            </S.LineLimiter>
            <VerticalSpacer space={4} />
            <S.CardInformation $cardProps={cardTheme}>{cardTheme.title}</S.CardInformation>
          </GenericContainer>
        </>
      );
    case CARD_TYPE_FOLDER:
      return (
        <Flex width={'100%'}>
          <S.FolderTitle>{cardTheme.name}</S.FolderTitle>
          {cardTheme.showPlusSign && <S.FolderPlusSign>+</S.FolderPlusSign>}
        </Flex>
      );
    case CARD_TYPE_CALENDAR_ITEM: {
      return (
        <>
          <VerticalSpacer space={15} />
          <Flex flexDirection={'row'} flexWrap={'no-wrap'} alignItems={'stretch'}>
            <div style={{ padding: '0 0 0 24px' }}>
              <S.CalendarCardDate>{cardTheme.date}</S.CalendarCardDate>
              <VerticalSpacer space={30} />
              <S.CalendarCardPlace>{cardTheme.place}</S.CalendarCardPlace>
              <VerticalSpacer space={5} />
              <S.CalendarCardLocation>{cardTheme.location}</S.CalendarCardLocation>
              <VerticalSpacer space={16} />
            </div>
            <Flex
              padding={'0 24px 0 0'}
              flexDirection={'column'}
              justifyItems={'flex-end'}
              justifyContent={'flex-start'}
            >
              <LSTVSVG flex="1" imageWidth={'36px'} imageHeight={'36px'} icon={'wedding-business-arrow'} />
            </Flex>
          </Flex>
        </>
      );
    }
    case CARD_TYPE_PROMO_VIDEO:
      return (
        <>
          <S.PromoVideoTitle>{cardTheme.title}</S.PromoVideoTitle>
        </>
      );
    case CARD_TYPE_MINI_ROUND_IMAGE:
      return (
        <>
          <S.MiniRoundedCardTitle>{cardTheme.title}</S.MiniRoundedCardTitle>
          {cardTheme.subtitle && !cardTheme.count && !cardTheme.countLabel && (
            <S.MiniRoundedCardSubTitle>{cardTheme.subtitle}</S.MiniRoundedCardSubTitle>
          )}
          {cardTheme.count && cardTheme.countLabel && (
            <S.MiniRoundedCardSubTitle>
              {shortHandValue(cardTheme.count)}{' '}
              <S.MiniRoundedCardSubTitleBold>{cardTheme.countLabel}</S.MiniRoundedCardSubTitleBold>
            </S.MiniRoundedCardSubTitle>
          )}
        </>
      );
    case CARD_TYPE_VENDOR_GENERIC:
      return (
        <>
          <Typography variant="h5" fontWeight="600">
            {cardTheme.name}
          </Typography>
          <S.CardInformation $cardProps={cardTheme}>{cardTheme.subTitle}</S.CardInformation>
        </>
      );
    case CARD_TYPE_AD:
      return (
        <>
          <Typography variant="h5" fontWeight="600">
            {cardTheme.name}
          </Typography>
          <CardInformation $cardProps={cardTheme}>{cardTheme.subtitle}</CardInformation>
          <AdLabelContainer>Ad</AdLabelContainer>
        </>
      );
  }
};

const ObservableThumbnail = ({ cardTheme, thumbnailUrl }: { cardTheme: any; thumbnailUrl: string }) => {
  const ref = useRef();
  const [animating, setAnimating] = useState(false);
  useEffect(() => {
    const el = ref.current;
    if (intersectionObserver && el) {
      observedElements.set(el, {
        callback: (active) => {
          setAnimating(active);
        },
      });
      intersectionObserver.observe(el);
      return () => {
        intersectionObserver.unobserve(el);
        observedElements.delete(el);
      };
    }
  }, [ref.current]);
  return (
    <S.CardThumbnail ref={ref} $cardProps={cardTheme}>
      <LSTVImage
        url={animating && cardTheme.previewGIFUrl ? cardTheme.previewGIFUrl : thumbnailUrl}
        alt={cardTheme.thumbnailAlt}
      />
      {/* <Image
        key={animating && cardTheme.previewGIFUrl ? cardTheme.previewGIFUrl : thumbnailUrl}
        src={animating && cardTheme.previewGIFUrl ? cardTheme.previewGIFUrl : thumbnailUrl}
        alt={cardTheme.thumbnailAlt}
        layout="fill"
        objectFit="cover"
        priority={true}/> */}

      {cardTheme.orientation === CARD_ORIENTATION_PORTRAIT && cardTheme.premium}
      {cardTheme.duration}
      {cardTheme.colorBar}
      {cardTheme.imageOnly && <S.GradientBackground $cardProps={cardTheme} />}
    </S.CardThumbnail>
  );
};

const LSTVCard = (props) => {
  const [thumbnailUrl, setThumbnailUrl] = useState(props.data.thumbnailUrl);
  const cardTheme = { ...props.options, ...props.data };
  cardTheme.duration = cardTheme.duration ? (
    <S.VideoDuration $cardProps={cardTheme}>{secsToTimeStr(cardTheme.duration)}</S.VideoDuration>
  ) : null;
  cardTheme.premium = cardTheme.premium ? <S.PremiumBadge>Suggested</S.PremiumBadge> : null;
  cardTheme.colorBar = null;

  let cardContent = (
    <ThemeProvider theme={cardTheme}>
      <S.CardOutline
        onMouseEnter={() => {
          if (intersectionObserver === undefined && cardTheme.previewGIFUrl) setThumbnailUrl(cardTheme.previewGIFUrl);
        }}
        onMouseLeave={() => {
          if (intersectionObserver === undefined && cardTheme.previewGIFUrl) setThumbnailUrl(cardTheme.thumbnailUrl);
        }}
        $cardProps={cardTheme}
      >
        <S.CardElements $cardProps={cardTheme}>
          {cardTheme.cardType !== CARD_TYPE_WEDDING_VENDOR && cardTheme.cardType !== CARD_TYPE_CALENDAR_ITEM && (
            <ObservableThumbnail {...{ cardTheme, thumbnailUrl }} />
          )}
          <S.CardDetails $cardProps={cardTheme}>{getCardDetails(cardTheme)}</S.CardDetails>
          {getBottomSection(cardTheme)}
        </S.CardElements>
      </S.CardOutline>
    </ThemeProvider>
  );

  // Should this card be a link?
  if (cardTheme.cardUrl || cardTheme.cardSlug) {
    return (
      <LSTVLink styled={false} to={`${cardTheme.cardUrl || cardTheme.cardSlug}`}>
        {cardContent}
      </LSTVLink>
    );
  } else if (cardTheme.clickHandler && cardTheme.clickHandler) {
    // or are we handling the card click?
    return (
      <S.ClickableCard
        $containerMode={props.options.containerMode}
        $cardType={cardTheme.cardType}
        onClick={() => {
          cardTheme.clickHandler(cardTheme.cardId || 'card without cardId defined.');
        }}
      >
        {cardContent}
      </S.ClickableCard>
    );
  } else {
    throw `${cardTheme.cardType} card options must include either cardSlug, cardUrl or [clickHandler + cardId]`;
  }
};

LSTVCard.propTypes = {
  options: PropTypes.object,
  data: PropTypes.object,
};

export default LSTVCard;
